import React, { useContext, useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import noteContext from '../context/notes/NoteContext';
import "./Addnote.css"

// in map or filter either use return with curly braces {}
// or use ( ) this bracket 

const Notecards = (props) => {

    const { notes } = props;
    const refClose = useRef(null)
    const [showMore, setShowMore] = useState(Array(notes.length).fill(false));
    const context = useContext(noteContext)


    const { deleteNote, editNote } = context
    const handleDeleteNote = (noteId) => {
        deleteNote(noteId)
        props.showAlert("Note deleted successfully", "warning")
    }

    const ref = useRef(null)
    // for editting note state
    const [enote, setEnote] = useState({ id: "", etitle: "", edescription: "", etag: "" })

    const handleedit = (id, title, description, tag) => {
        ref.current.click()
        setEnote({ id: id, etitle: title, edescription: description, etag: tag })
        // console.log("here is editted note using state", enote)
    }
    const onChange = (e) => {
        setEnote({ ...enote, [e.target.name]: e.target.value })
    }

    const handleSaveChanges = () => {
        console.log("updating your note...")
        refClose.current.click()
        editNote(enote.id, enote.etitle, enote.edescription, enote.etag)
        props.showAlert("Note editted successfully", "success")
    }
    const handleShowMore = (noteId, len) => {
        setShowMore((prevShowMore) => {
            const updatedShowMore = [...prevShowMore];       
            updatedShowMore[noteId] = !updatedShowMore[noteId];
            return updatedShowMore;
        });
    };

    
    const isShareSupported = navigator.share !== undefined;
    const handleShareClick = async (note) => {
        try {
            if (isShareSupported) {
                await navigator.share({
                    title: note.title,
                    text: note.description,
                    url: window.location.href,
                });
            } else {
                console.log('Sharing options not supported.');
            }
        } catch (error) {
            console.error('Error sharing note:', error);
        }
    };

    return (
        <>
            <button type="button" ref={ref} className="btn btn-primary d-none" data-toggle="modal" data-target="#exampleModal">Open Model</button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="exampleModalLabel">Edit Note <ModeEditIcon /></h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="left">
                                <div className="form-group">
                                    <label htmlFor="title">Title</label>
                                    <input type="text" value={enote.etitle} onChange={onChange} name='etitle' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <input type="text" value={enote.edescription} id='description' name='edescription' onChange={onChange} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tag">Tag</label>
                                    <input type="text" value={enote.etag} id='tag' name='etag' onChange={onChange} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" ref={refClose} data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSaveChanges} >Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>




            <div className='container mx-3 my-5 w-80 mx-auto d-flex flex-wrap justify-content-around'>
                {notes.map((note, index) => (
                    <Card key={index} id={`card-${index}`} className={`note-cards card`} style={{
                        height:showMore[index] ? 'auto': '9rem',
                        width: '25rem'
                        }}>
                        <div className="note-card-left">
                            <CardContent>
                                <Typography className='cards-body d-flex flex-wrap align-items-center' style={{width: '15rem'}} gutterBottom variant="h5" component="div">
                                    <span style={{fontSize: '1.3rem', marginRight: '0.5rem'}}>{note.title}</span> <span style={{ fontSize: "1rem" }}>{note.tag}</span>
                                </Typography>
                                <Typography id={`note-${index}`} variant="body2" color="text.primary" style={{ wordWrap: 'break-word' }}>
                                    {showMore[index] ? note.description : `${note.description.slice(0, 30)}...`}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={handleShareClick}>Share</Button>
                                {/* {showMore[index] && <Button className='show-more' size="small" onClick={() => { handleShowMore(index, note.description.length); console.log(index) }}>Show More</Button>} */}
                                {showMore[index] ? (<Button className='show-less' size="small" onClick={() => { handleShowMore(index, note.description.length) }} >Show Less</Button>)
                                    : <Button className='show-more' size="small" onClick={() => { handleShowMore(index, note.description.length) }}>Show More</Button>}
                            </CardActions>
                        </div>
                        <div className="note-card-right position-absolute" style={{ top: '11px', right: '10px' }}>
                            <IconButton onClick={() => { handleDeleteNote(note._id) }}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={() => { handleedit(note._id, note.title, note.description, note.tag) }}>
                                <ModeEditIcon />
                            </IconButton>
                        </div>
                    </Card>
                ))}
            </div>
        </>
    );
};

export default Notecards;
