import React, { useContext, useEffect, useState } from 'react'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import { Box } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import LabelIcon from '@mui/icons-material/Label';
import ExpandLess from '@mui/icons-material/ExpandLess';
import TocTwoToneIcon from '@mui/icons-material/TocTwoTone';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import "./Addnote.css";

import noteContext from '../context/notes/NoteContext';
import Notecards from './Notecards';

const Addnote = (props) => {
    const context = useContext(noteContext)
    const { notes, addNote, getNotes } = context

    const [openSavedNotes, setOpenSavedNotes] = useState(false);
    const [openLabels, setOpenLabels] = useState(false);

    useEffect(() => {
        getNotes()
        // eslint-disable-next-line
    }, [])

    const handleClickSavedNotes = () => {
        setOpenSavedNotes(!openSavedNotes);
    };

    const handleClickLabels = () => {
        setOpenLabels(!openLabels);
    };

    const [contextNote, setContextNote] = useState({ title: "", description: "", tag: "" })
    const onChange = (e) => {
        // means setnote me jo note object ha vo rhe but the properties which i write must 
        // overwride them
        setContextNote({ ...contextNote, [e.target.name]: e.target.value })
    }

    const handleAddNote = (e) => {
        e.preventDefault()
        addNote(contextNote.title, contextNote.description, contextNote.tag)
        props.showAlert("Note added successfully", "success")
        // from context 
        setContextNote({ title: "", description: "", tag: "" })
    }



    return (
        <>
            <div className='container mt-5 d-flex'>
                <form className='content'>
                    <div style={{ 'width': '90%' }}>
                        <h2 className='my-3'>Add Note</h2>
                        <div className="left">
                            <div className="form-group">
                                <label htmlFor="title">Title</label>
                                <input type="text" onChange={onChange} value={contextNote.title} name='title' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your Note with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <input type="text" id='description' value={contextNote.description} name='description' onChange={onChange} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="tag">Tag</label>
                                <input type="text" id='tag' value={contextNote.tag} name='tag' onChange={onChange} className="form-control" />
                            </div>
                            <button disabled={contextNote.title.length < 3 || contextNote.description.length < 5} type="submit" onClick={handleAddNote} className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
                <div className="sidebar col-md-2">
                    <Box sx={{ width: 240, position: 'absolute' }}>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Track Your Notes
                                </ListSubheader>
                            }
                        >
                            <ListItemButton onClick={handleClickSavedNotes}>
                                <ListItemIcon>
                                    <LibraryAddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Saved Notes" />
                                {openSavedNotes ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openSavedNotes} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {notes.map((note, index) => (
                                        <ListItemButton key={index} sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <TocTwoToneIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={note.title} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                            <ListItemButton onClick={handleClickLabels}>
                                <ListItemIcon>
                                    <LabelIcon />
                                </ListItemIcon>
                                <ListItemText primary="Labels" />
                                {openLabels ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openLabels} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText primary="Starred" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton>
                                <ListItemIcon>
                                    <BookmarkIcon />
                                </ListItemIcon>
                                <ListItemText primary="Bookmarks" />
                            </ListItemButton>
                        </List>
                    </Box>
                </div>
            </div>
            {notes.length === 0 && <h3 className='container my-4'>No notes to display</h3>}
            <div className="cards d-flex">
                <Notecards notes={notes} onChange={onChange} showAlert={props.showAlert} />
            </div>
        </>
    )
}

export default Addnote
