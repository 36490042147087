import React, { useState } from 'react'
import NoteContext from './NoteContext'

// this function is helping us or give priviledge to provide this authority
// OF context to all the children wrap in it 
const NoteState = (props) => {
    // const host = "http://localhost:5000"
    const host = "https://enote-api.onrender.com"
    const initialNotes = []
    const [notes, setNotes] = useState(initialNotes)

    // Get all notes
    const getNotes = async () => {
        console.log("fetching all notes");
        // api call in backend side
        const response = await fetch(`${host}/api/notes/fetchallnotes`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "auth-token":
                    localStorage.getItem('token'),
            },
            body: JSON.stringify(),
        });
        const json = await response.json();
        console.log(json);
        setNotes(json)
    };


    // Add Note
    const addNote = async (title, description, tag) => {
        console.log("adding a new note");
        // api call in backend side
        const response = await fetch(`${host}/api/notes/addnote`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "auth-token":
                    localStorage.getItem('token'),
            },
            body: JSON.stringify({ title, description, tag }),
        });
        const note = await response.json();
        setNotes(notes.concat(note));
    };


    // Delet Note
    const deleteNote = async (noteId) => {
        // api call in backend side
        const response = await fetch(`${host}/api/notes/deletenote/${noteId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem('token')
            },
            referrerPolicy: "no-referrer"
        });
        const json = await response.json()
        console.log(json)
        console.log('deleting note:id ', noteId)
        const newNotes = notes.filter((note) => { return note._id !== noteId })
        setNotes(newNotes)
    }

    // Update NOte
    const editNote = async (id, title, description, tag) => {
        // api call in backend side
        const response = await fetch(`${host}/api/notes/updatenote/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem('token')
            },
            body: JSON.stringify({ title, description, tag }),
        });

        // const json = response.json()
        // console.log(json)

        if (response.ok) {
            const updatedNotes = notes.map((note) =>
                note._id === id ? { ...note, title, description, tag } : note
            );
            setNotes(updatedNotes);
        } else {
            console.log("Failed to update note");
        }
    }


    // means in context we can send the function also to update the state
    return (
        // <NoteContext.Provider value={{state: state, update: update}}>
        <NoteContext.Provider value={{ notes, addNote, editNote, deleteNote, getNotes }}>
            {props.children}
        </NoteContext.Provider>
    )
}

export default NoteState;