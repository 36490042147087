import React from 'react'
import "./About.css"

const About = () => {
  
  
  return (
    <div>
      {/* as i made two objects in context so use of that member is important */}
      <div className="container" id='container1'>
        <div className="left1">
            <img src="images/DataArranging.svg" alt="" />
        </div>
        <div className="right1 py-3">
          <h1 className='py-3'>Make something Awesome</h1>
          <h5>iNotebook is made from the pain of writing all the things in notebook which is very hectic :(, so we mad an online web platform where you can create, edit, upload, delete your notes/information privately and securely without any disturbancee you can also access your notes anywhere in your world, at anytime time. So dont forget to Create note because creating anything is always important</h5>
        </div>
      </div>
      <div className="container" id='container2'>
        <div className="left2 py-5">
          <h1 className='py-3'>Powering the internet's visuals</h1>
          <h5>How we started? The concept was simple. iNotebook was born from the pain of writing all the things in notebook which is very hectic :(. An online web platform where you can create, edit, upload, delete your notes/information privately and securely without any disturbancee</h5>
        </div>
        <div className="right2">
            <img src="images/undrawknowledge.svg" alt="" />
        </div>
      </div>
    </div>
  )
}

export default About
