import React from 'react';

const Alert = ({alert}) => {
    return (
        <div style={{ position: 'absolute', width: '100%' }}>
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                {alert.message}
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    );
};

export default Alert;
