import { useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Notestate from './context/notes/NoteState';
import Note from './components/Note';
import Alert from './components/Alert';   // although we can use context api but 
// our application becomes complex and the alert use is more

function App() {
  const [alert, setAlert] = useState(null)

  const showAlert = (msg, type) => {
    setAlert({ message: msg, type: type })

    // Automatically clear the alert after a certain time (e.g., 3 seconds)
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  return (
    <>
      {/* declared it globally so that its all the inner components can use authentiction */}
      <Notestate>
        <Router>
          <Navbar />
          {alert && <Alert alert={alert} />}
          <div className="mx-1">
            <Routes>
              <Route index path='/' element={<Home showAlert={showAlert} />} />
              <Route path='/notes' element={<Note showAlert={showAlert} />} />
              <Route exact path='/about' element={<About />} />
            </Routes>
          </div>
        </Router>
      </Notestate>
    </>
  );
}

export default App;
