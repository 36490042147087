import React, { useState } from 'react';
import './Home.css';
import { useNavigate, Link } from "react-router-dom";

const Home = (props) => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "" })
  const [userData, setUserData] = useState({ name: "", email: "", password: "" })


  const handleLogin = async () => {
    // const response = await fetch(`http://localhost:5000/api/auth/login`, {
    const response = await fetch(`https://enote-api.onrender.com/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: credentials.email, password: credentials.password }),
    });
    const json = await response.json()
    console.log(json)
    if (json.success) {
      props.showAlert("Login Successfully", "success");

      // save the auth token and redirect 
      localStorage.setItem('token', json.authToken)
      navigate("/notes")
      
      setTimeout(() => {
        localStorage.removeItem('token');
        // navigate("/")
      }, 3600000);
      // after 1 minutes user will logout
    }
  }
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  
  const onchange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const handleSign = async () => {
    const response = await fetch(`https://enote-api.onrender.com/api/auth/createuser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({name: userData.name, email: userData.email, password: userData.password}),
    });
    const json = await response.json()
    console.log(json)
    if(json.success){
      props.showAlert("Signin Successfully", "primary");

      // save the auth token and redirect 
      localStorage.setItem('token', json.authToken)
      navigate("/notes")
    }
  }

  return (
    <div className="container1 container" style={{marginTop: '3rem'}}>
      <input type="checkbox" id="flip" />
      <div className="cover">
        <div className="front">
          <img src="https://media.istockphoto.com/id/1079719138/photo/your-clients-are-waiting-to-hear-from-you.webp?b=1&s=170667a&w=0&k=20&c=0RcYK39vkvm27E-na_obGDMxgP0ODXteAEhoZcCLyew=" alt="" />
          <div className="text">
            <span className="text-1">Every new friend is a <br /> new adventure</span>
            <span className="text-2">Let's get connected</span>
          </div>
        </div>
        <div className="back">
          <img className="backImg" src="https://www.istockphoto.com/search/2/image?phrase=landscapes" alt="loading.." />
          <div className="text">
            <span className="text-1">Complete miles of journey <br /> with one step</span>
            <span className="text-2">Let's get started</span>
          </div>
        </div>
      </div>
      <div className="forms">
        <div className="form-content">
          <div className="login-form">
            <div className="title">Login</div>
            <form action="#">
              <div className="input-boxes">
                <div className="input-box">
                  <i className="fas fa-envelope"></i>
                  <input onChange={onChange} name="email" type="text" placeholder="Enter your email" required />
                </div>
                <div className="input-box">
                  <i className="fas fa-lock"></i>
                  <input onChange={onChange} name="password" type="password" placeholder="Enter your password" required />
                </div>
                <div className="text"><Link to="#">Forgot password?</Link></div>
                <div className="button input-box">
                  <input onClick={(e) => { e.preventDefault(); handleLogin(); console.log("clicked") }} type="submit" value="Sumbit" />
                </div>
                <div className="text sign-up-text">Don't have an account? <label htmlFor="flip">Sigup now</label></div>
              </div>
            </form>
          </div>
          <div className="signup-form">
            <div className="title">Signup</div>
            <form action="#">
              <div className="input-boxes">
                <div className="input-box">
                  <i className="fas fa-user"></i>
                  <input onChange={onchange} name="name" type="text" placeholder="Enter your name" required />
                </div>
                <div className="input-box">
                  <i className="fas fa-envelope"></i>
                  <input onChange={onchange} name="email" type="text" placeholder="Enter your email" required />
                </div>
                <div className="input-box">
                  <i className="fas fa-lock"></i>
                  <input onChange={onchange} name="password" type="password" placeholder="Enter your password" required />
                </div>
                <div className="button input-box">
                  <input onClick={(e) => { e.preventDefault(); handleSign(); console.log("clicked") }} type="submit" value="Sumbit" />
                </div>
                <div className="text sign-up-text">Already have an account? <label htmlFor="flip">Login now</label></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;