import React from 'react'
import Addnote from './Addnote';

const Note = (props) => {
    return (
        <Addnote showAlert={props.showAlert}/>
    )
}

export default Note
